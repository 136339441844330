import { IsEnableLandline, smartHomeUUID } from '@/data/src/community';
import { CallTypeListData } from './all.type';

const getCallType = (): Array<CallTypeListData> => {
    const enableLandLineCallType = [{
        label: WordList.ProperAllTextSmartPlusIndoor,
        value: 0
    }, {
        label: WordList.ProperAllTextPhoneIndoor,
        value: 1
    }, {
        label: WordList.ProperAllTextSmartPlusIndoorBackup,
        value: 2
    }, {
        label: WordList.ProperAllTextIndoorSmartPlusBackup,
        value: 3
    }, {
        label: WordList.ProperAllTextIndoorPhoneBackup,
        value: 4
    }, {
        label: WordList.ProperAllTextIndoorSmartPlusPhone,
        value: 5
    }];
    const unableLandLineCallType = [{
        label: WordList.ProperAllTextSmartPlusIndoor,
        value: 0
    }, {
        label: WordList.ProperAllTextIndoorSmartPlusBackup,
        value: 3
    }];
    if (IsEnableLandline.value === 0) {
        return unableLandLineCallType;
    }
    return enableLandLineCallType;
};

export default null;
export {
    getCallType
};