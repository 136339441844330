
import dialogShell from '@/components/common/dialog-shell/index.vue';
import {
    defineComponent, PropType, reactive, ref, Ref,
    toRefs,
    watch
} from 'vue';
import { community } from '@/data';
import Big from 'big.js';
import { byteLength } from 'byte-length';
import { setting as settingRule } from '@/methods/rule';
import { IsEnableLandline, smartHomeUUID } from '@/data/src/community';
import noticeBase from '@/util/notice.base';
import { checkAmount } from '@/methods/rules/user';
import decimalInput from '@/components/common/decimal-input';
import { apartmentForPMApi, residentDeviceApi } from '@/api';
import { CallTypeListData } from './all.type';
import { getCallType } from './util';

interface AptForm {
    ID: string;
    AptName: string;
    CallType: number;
    EnableIpDirect: number;
    TempKeyPermission: number;
    LimitCreateQRcode: number;
    LimitFamilyMember: number;
    FamilyMemberControl: number;
    AllowCreateSlaveCnt: string | number;
    LimitRegisterFace: number;
    RegisterFaceControl: number;
    Area: string;
    CommunalFee: string;
    AutoSendBills: number;
    IsDefaultCommunalFee: number;
}
export default defineComponent({
    components: {
        dialogShell,
        decimalInput
    },
    props: {
        initForm: {
            type: Object as PropType<AptForm>,
            required: true
        }
    },
    setup(props, { emit }) {
        const defaultCommunalFee = String(community.defaultCommunalFee.value);
        const isOpenCommunal = community.isOpenCommunalFee.value;
        const apartmentForm: Ref<any> = ref();
        const isShowExpireTip = ref(false);
        const isShowExpireTip2 = ref(false);
        const isShowExpireTip3 = ref(false);

        const checkApartmentName: RuleMethod = (rule, value, callback) => {
            if (byteLength(value as string) > 63) {
                return callback(
                    new Error(
                        WordList.RuleLengthExceeds63.format(
                            WordList.ProperAllTextApartmentName,
                            WordList.ProperAllTextApartmentName
                        )
                    )
                );
            }
            return callback();
        };

        const rules = {
            AllowCreateSlaveCnt: [
                { required: true, message: WordList.RuleCommonLabelEmpty2.format(WordList.OrderAmount), trigger: 'blur' },
                { validator: checkAmount, trigger: 'blur' }
            ],
            CommunalFee: [
                { validator: settingRule.onlyPositiveNumber, trigger: 'blur' }
            ],
            AptName: [
                { validator: checkApartmentName, trigger: 'blur' }
            ]
        };
        const formData = reactive({
            ...props.initForm
        });
        const getFamilyMemberLimitRule = () => {
            if (formData.FamilyMemberControl === 0) {
                rules.AllowCreateSlaveCnt = [];
            } else {
                rules.AllowCreateSlaveCnt = [
                    { required: true, message: WordList.RuleCommonLabelEmpty2.format(WordList.OrderAmount), trigger: 'blur' },
                    { validator: checkAmount, trigger: 'blur' }
                ];
            }
        };
        getFamilyMemberLimitRule();
        const checkQRCode = () => {
            // QRcode没有高级功能显示的都是on,off且过期显示过期提示语
            if (formData.LimitCreateQRcode === 0) {
                formData.TempKeyPermission = 1;
            } else if (formData.TempKeyPermission === 0 && formData.LimitCreateQRcode === 2) {
                isShowExpireTip.value = true;
                formData.TempKeyPermission = 0;
            }
        };
        const limitRoomNameType = (val: string) => {
            const reg = /[^\d]]*/g;
            formData.AllowCreateSlaveCnt = val.replace(reg, '');
        };
        checkQRCode();
        const checkFamilyMemberControl = () => {
            // 家庭成员限制没有高级功能显示的都是off,on且过期显示过期提示语
            if (formData.LimitFamilyMember === 0) {
                formData.FamilyMemberControl = 0;
            } else if (formData.FamilyMemberControl === 1 && formData.LimitFamilyMember === 2) {
                isShowExpireTip2.value = true;
                formData.FamilyMemberControl = 1;
            }
        };
        checkFamilyMemberControl();
        const close = () => {
            emit('close');
        };
        const submit = () => {
            apartmentForm.value.validate((valid: boolean) => {
                if (valid) {
                    // 如果跟随社区则恢复初始值
                    const params = { ...formData };
                    if (params.IsDefaultCommunalFee === 1) {
                        params.CommunalFee = defaultCommunalFee;
                    }
                    apartmentForPMApi.editApartment(params, () => {
                        emit('success');
                        close();
                    });
                }
            });
        };

        const callTypeList: Ref<Array<CallTypeListData>> = ref([]);
        watch([IsEnableLandline, smartHomeUUID], () => {
            callTypeList.value = getCallType();
        }, { immediate: true });

        watch(() => formData.FamilyMemberControl, () => {
            getFamilyMemberLimitRule();
        });

        const changeUserQRCode = () => {
            // 1.不存在高级功能，只显示on 2.过期且切换到off，显示提示语且无法切换 3.其余情况正常切换
            if (formData.LimitCreateQRcode === 0) {
                noticeBase.messageBox('alert', WordList.ProperAllTextactivateFeatureTips, WordList.PersonuserInfo, 'warning')(() => false);
                formData.TempKeyPermission = 1;
            } else if (formData.LimitCreateQRcode === 2 && formData.TempKeyPermission === 0) {
                formData.TempKeyPermission = 1;
                isShowExpireTip.value = true;
            } else {
                isShowExpireTip.value = false;
            }
        };

        const changeUserFamilyMember = () => {
            // 1.不存在高级功能，只显示off 2.过期且切换到on，显示提示语且无法切换 3.其余情况正常切换
            if (formData.LimitFamilyMember === 0) {
                noticeBase.messageBox('alert', WordList.ProperAllTextactivateFeatureTips, WordList.PersonuserInfo, 'warning')(() => false);
                formData.FamilyMemberControl = 0;
            } else if (formData.LimitFamilyMember === 2 && formData.FamilyMemberControl === 1) {
                formData.FamilyMemberControl = 0;
                isShowExpireTip2.value = true;
            } else {
                isShowExpireTip2.value = false;
            }
        };

        const changeUserRegisterFace = () => {
            // 1.不存在高级功能，只显示On 2.过期且切换到off，显示提示语且无法切换 3.其余情况正常切换
            if (formData.LimitRegisterFace === 0) {
                noticeBase.messageBox(
                    'alert',
                    WordList.ProperAllTextactivateFeatureTips,
                    WordList.PersonuserInfo,
                    'warning'
                )(() => false);
                formData.RegisterFaceControl = 1;
            } else if (formData.LimitRegisterFace === 2 && formData.RegisterFaceControl === 0) {
                formData.RegisterFaceControl = 1;
                isShowExpireTip3.value = true;
            } else {
                isShowExpireTip3.value = false;
            }
        };

        // 重置room
        function resetRoom() {
            noticeBase.messageBox(
                'confirm',
                WordList.ResetTips,
                WordList.TabelDeleteTitle,
                'warning'
            )(() => {
                residentDeviceApi.resetRoom({
                    ID: formData.ID
                }, () => {
                    emit('success');
                    close();
                });
            });
        }

        // 每月公维金
        const allCommunalFee = ref('');
        watch(formData, () => {
            const leftBig = formData.CommunalFee ? Number(formData.CommunalFee) : defaultCommunalFee;
            const rightBig = formData.Area ? Number(formData.Area) : 0;
            if (!Number.isNaN(leftBig) && !Number.isNaN(rightBig)) {
                allCommunalFee.value = Big(leftBig).times(rightBig).toFixed(2);
            }
        }, { immediate: true });

        // 初始时判断是否跟随社区，跟随社区则暂时置空变灰色默认值
        if (formData.IsDefaultCommunalFee === 1) {
            formData.CommunalFee = '';
        }
        // 判断是否跟随社区默认值
        function handleCommunalInputBlur(event: FocusEvent) {
            // 如果有输入值则不跟随社区
            if (formData.CommunalFee) {
                formData.IsDefaultCommunalFee = 0;
            } else {
                formData.IsDefaultCommunalFee = 1;
            }
        }

        return {
            toRefs,
            defaultCommunalFee,
            isOpenCommunal,
            formData,
            submit,
            close,
            apartmentForm,
            callTypeList,
            isShowExpireTip,
            checkQRCode,
            changeUserQRCode,
            isShowExpireTip2,
            checkFamilyMemberControl,
            changeUserFamilyMember,
            limitRoomNameType,
            rules,
            getFamilyMemberLimitRule,
            isShowExpireTip3,
            changeUserRegisterFace,
            resetRoom,
            allCommunalFee,
            handleCommunalInputBlur
        };
    }
});

